<template>
  <b-row v-if="!isLoading" class="justify-content-md-center">
    <b-col cols="12" md="12" style="max-width: 820px">
      <!-- ** QUESTION ** -->
      <unshare-item-modal
        v-model="isUnshareModalVisible"
        model-type="forum"
        @remove-item="removeItem(item)"
        @modal-invisible="isUnshareModalVisible = false"
      />
      <unshare-item-modal
        v-model="isUnshareAnswerModalVisible"
        model-type="answer"
        @remove-item="deleteAnswer(answerData.key)"
        @modal-invisible="isUnshareAnswerModalVisible = false"
      />
      <unshare-item-modal
        v-model="isUnshareCommentModalVisible"
        model-type="comment"
        @remove-item="deleteComment(commentKey, answerData)"
        @modal-invisible="isUnshareCommentModalVisible = false"
      />
      <b-card v-if="item && validForum.includes(id)" no-body>
        <b-card-body class="no-padding">
          <b-img
            v-if="!editing"
            fluid
            :src="getImageSrc(item.bgURL)"
            class="d-block"
            style="
              max-height: 500px;
              width: 100%;
              border-top-left-radius: 1.2rem;
              border-top-right-radius: 1.2rem;
            "
          />
          <b-card-group
            v-if="editing"
            :label="$t('backoffice.onboarding-form.form.image')"
            label-for="image"
            class="d-flex w-100 justify-content-center"
          >
            <file-preview
              v-if="!editForm.bgURL && !image"
              :src="Placeholder"
              mime-type="image"
              style="height:300px; width:80%; margin-bottom: 20px; border-radius: 17px;"
            />
            <file-preview
              v-if="editForm.bgURL"
              :src="getImageSrc( editForm.bgURL)"
              :placeholder-image="Placeholder"
              mime-type="image"
              cancelable
              @cancel="editForm.bgURL = null"
            />
            <file-upload
              v-else
              v-model="image"
              type="image"
              cancelable
              :placeholder="$t('form-create-item.image-button')"
              :drop-placeholder="$t('form-create-item.drop-placeholder')"
              @cancel="image = null"
            />
          </b-card-group>
          <div class="p-2">
            <b-badge
              v-if="status"
              class="my-50"
              :variant="getBadgeVariant(status)"
            >
              {{ getBadgeText(status) }}
            </b-badge>
            <!-- Question Name -->
            <div v-if="!editing" class="d-flex mt-1 justify-content-between">
              <h3>
                {{ translate(item.name) }}
              </h3>
              <div style="width: 200px">
                <feather-icon
                  v-if="canEdit(item) && !editing"
                  icon="Edit2Icon"
                  size="20"
                  class="text-primary edit-icon float-right ml-1"
                  role="button"
                  @click="openEdit"
                />
                <feather-icon
                  v-if="canDelete(item) && !editing"
                  icon="Trash2Icon"
                  size="20"
                  style="cursor: pointer"
                  class="text-primary edit-icon float-right ml-1"
                  role="button"
                  @click="isUnshareModalVisible = true"
                />
              </div>
            </div>
            <b-form-input
              v-else
              v-model="editForm.name"
              :placeholder="
                translate(item.name)
              "
              class="mt-1 font-medium-4 font-weight-bold"
            />
            <div class="float-right" style="width: 200px" />
            <!-- Question info -->
            <b-card-text v-if="item.createdAt" class="text-muted mt-1 mb-2">
              {{ timestampToNow(item.createdAt) }}
              ·
              {{
                $tc("forums.answers", forumAnswersTotal, { count: forumAnswersTotal })
              }}
              ·
              {{
                $tc("social.likes", item.totalLikes, { count: item.totalLikes })
              }}
            </b-card-text>
            <hr>
            <b-col v-if="item.ownedByMember" cols="12" class="pt-75 ml-0 pl-0">
              <!-- Owner -->
              <b-link
                :to="{
                  name: 'member-details',
                  params: { username: item.ownedByMember.username },
                }"
              >
                <b-avatar
                  size="38"
                  :src="getImageSrc(item.ownedByMember.avatarURL)"
                  :alt="item.ownedByMember.name || Object.values(item.name)[0]"
                  class="user-avatar"
                />
              </b-link>

              <span v-if="item.ownedByMember" class="mb-1 ml-1">
                {{ item.ownedByMember.name }} {{ item.ownedByMember.surname }}
                <span
                  v-if="item.ownedByMember.headline"
                  class="ml-50 text-muted"
                >
                  {{ item.ownedByMember.headline }}
                </span>
              </span>
            </b-col>

            <!-- Description -->
            <b-row class="ml-3 mr-1">
              <b-col
                v-if="editing"
                cols="12"
                class="pt-1 mb-2"
              >
                <quill-editor
                  v-model="editForm.description"
                />
                <div class="d-flex justify-content-end mt-2">
                  <b-button
                    class="mt-n50 mr-75"
                    variant="primary"
                    @click="handleEditItem"
                  >
                    {{ $t("form.actions.save-changes") }}
                  </b-button>
                  <feather-icon
                    icon="XCircleIcon"
                    size="24"
                    class="text-primary edit-icon"
                    role="button"
                    @click="editing = !editing"
                  />
                </div>
              </b-col>
              <b-col v-else cols="12">
                <b-card-text
                  v-if="translate(item.description)"
                  class="mt-2 mb-1"
                >
                  <span
                    class="content-container"
                    v-html="translate(item.description)"
                  />
                </b-card-text>

                <!-- Icons -->
                <div
                  class="d-flex align-items-center justify-content-between mt-2"
                >
                  <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center mr-1">
                      <span
                        class="hover-button mx-1"
                        role="button"
                        @click="toggleLike('forums', 'forum', id, null, 'forum')"
                      >
                        <feather-icon
                          size="20"
                          :class="
                            item.likedByMember
                              ? 'profile-likes-primary'
                              : 'profile-icon'
                          "
                          icon="ThumbsUpIcon"
                          class="mr-25"
                        />
                        <span v-if="!item.likedByMember">
                          {{ $t("forums.icons.like") }}</span>
                        <span v-else> {{ item.totalLikes }}</span>
                      </span>
                      <b-link href="#commentForm" class="text-dark">
                        <span
                          role="button"
                          class="pointer"
                        >
                          <feather-icon size="20" icon="EditIcon" class="mr-25" />
                          {{ $t("forums.icons.create-answer") }}
                        </span>
                      </b-link>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card-body>
      </b-card>
      <!-- ** ANSWERS ** -->
      <b-card v-if="forumAnswersAll.length > 0" class="p-0" no-body>
        <!-- Header -->
        <h5 class="pt-2 pb-1 pl-2 pb-0">
          {{ $tc("forums.answers", forumAnswersTotal, { count: forumAnswersTotal }) }}
        </h5>
        <hr v-if="forumAnswersAll.length > 0" class="m-0">
        <!-- Answers loop -->

        <div class="pl-3 pr-2">
          <b-row
            v-for="(answer, index) in forumAnswersAll"
            :key="answer.key"
            class="py-1"
            :class="index != 0 ? ' border-top' : ''"
          >
            <!-- Left -->
            <h3>{{ answer.answerToUpdate }}</h3>
            <b-col cols="1" class="p-0">
              <div class="m-auto" style="width: 38px">
                <b-link
                  :to="{
                    name: 'member-details',
                    params: { username: answer.ownedByMember.username },
                  }"
                >
                  <b-avatar
                    v-if="answer.ownedByMember"
                    size="38"
                    :src="getImageSrc(answer.ownedByMember.avatarURL)"
                    :alt="answer.ownedByMember.name"
                    class="user-avatar"
                  />
                </b-link>
              </div>
              <div
                v-if="canShowCheck(answer)"
                class="w-100 mt-75 text-center"
              >
                <feather-icon
                  role="button"
                  size="24"
                  :stroke="answer.correctAnswer ? 'green' : 'lightgray'"
                  stroke-width="3"
                  icon="CheckIcon"
                  @click="selectBestAnswer(answer.key)"
                />
              </div>
              <div class="text-secondary text-center" :class="canShowCheck(answer)?'mt-25':'mt-2'">
                <feather-icon
                  :class="{ 'text-primary': answer.likedByMember }"
                  class="w-100 icon-clickable"
                  icon="ArrowUpIcon"
                  size="20"
                  @click="toggleLike('forumAnswers', 'answer', answer.key)"
                />
                <h6
                  class="w-100"
                  style="
                    font-weight: 600;
                    margin-top: 0.3rem;
                    margin-bottom: 0.3rem;
                  "
                >
                  {{ answer.totalLikes }}
                </h6>
                <feather-icon
                  class="w-100 icon-clickable"
                  icon="ArrowDownIcon"
                  size="20"
                  @click="!answer.likedByMember || toggleLike('forumAnswers', 'answer', answer.key)"
                />
              </div>
            </b-col>
            <!-- Right -->
            <b-col cols="11" class="pt-50">
              <span v-if="answer.ownedByMember" class="mt-4">
                {{ answer.ownedByMember.name }}
                {{ answer.ownedByMember.surname }}
              </span>
              <span
                v-if="answer.ownedByMember.headline"
                class="ml-50 text-muted"
              >
                {{ answer.ownedByMember.headline }}
              </span>
              <span v-if="item.createdAt" class="float-right mr-2 text-muted">
                {{ timestampToNow(answer.createdAt) }}
              </span>
              <b-col
                v-if="editingAnswer && answerKey === answer.key"
                cols="12"
                class="pt-1 mb-2"
              >
                <quill-editor
                  v-model="editAnswerForm"
                />
                <div class="d-flex justify-content-end mt-2">
                  <b-button
                    class="mt-n50 mr-75"
                    variant="primary"
                    @click="handleEditAnswer"
                  >
                    {{ $t("form.actions.save-changes") }}
                  </b-button>
                  <feather-icon
                    icon="XCircleIcon"
                    size="24"
                    class="text-primary edit-icon"
                    role="button"
                    @click="editingAnswer = !editingAnswer"
                  />
                </div>
              </b-col>
              <b-col v-else cols="12">
                <p
                  v-if="answer.description"
                  class="my-2"
                  v-html="translate(answer.description)"
                />
                <!-- Answer footer: Button open reply, comments  and input reply-->
                <div
                  class="w-100 pb-2 pt-50 float-left"
                >
                  <!-- Button open input new answer  -->
                  <span class="d-flex float-left" :class="answer.showReply?'mb-1':''">
                    <div
                      role="button"
                      class="hover-button"
                      @click="toggleReply(answer)"
                    >
                      <feather-icon
                        icon="CornerUpLeftIcon"
                        size="18"
                        class="mr-50"
                      />
                      <span class="mb-0">{{ $t("social.reply") }}</span>
                    </div>
                    <span
                      class="hover-button mx-1"
                      role="button"
                      @click="toggleLike('forumAnswers', 'answer', answer.key, answer)"
                    >
                      <feather-icon
                        size="20"
                        :class="
                          item.likedByMember
                            ? 'profile-likes-primary'
                            : 'profile-icon'
                        "
                        icon="ThumbsUpIcon"
                        class="mr-25"
                      />
                      <span v-if="!answer.likedByMember">
                        {{ $t("forums.icons.like") }}</span>
                      <span v-else>{{ answer.totalLikes }}</span>
                    </span>
                    <span
                      class="hover-button  mx-1"
                      role="button"
                      @click="toggleComments(answer)"
                    >
                      <feather-icon
                        role="button"
                        size="20"
                        icon="MessageSquareIcon"
                        class="mr-25"
                      />
                      <span>{{ answer.commentCount || 0 }}</span>
                    </span>
                  </span>
                  <feather-icon
                    v-if="canDelete(answer)"
                    icon="Trash2Icon"
                    size="20"
                    class="text-primary edit-icon float-right ml-1"
                    role="button"
                    @click="
                      (answerData = answer), (isUnshareAnswerModalVisible = true)
                    "
                  />
                  <feather-icon
                    v-if="canEdit(answer)"
                    icon="Edit2Icon"
                    size="20"
                    class="text-primary edit-icon float-right ml-1"
                    role="button"
                    @click="selectAnswer(answer)"
                  />

                  <!-- Form Reply -->
                  <b-form
                    v-if="answer.showReply"
                    ref="questionForm"
                    class="chat-app-form pt-1 w-100"
                    @submit.prevent="postComment(answer)"
                  >
                    <b-input-group
                      class="input-group-merge form-send-message mr-1 w-100"
                    >
                      <b-form-textarea
                        v-model="newComment"
                        rows="5"
                        :placeholder="$t('questions.message')"
                      />
                    </b-input-group>
                    <b-button
                      variant="primary"
                      type="submit"
                      class="mt-50 float-right"
                    >
                      {{ $t("send.button") }}
                    </b-button>
                  </b-form>
                </div>
              </b-col>

              <!-- Comments to answers -->
              <div v-if="showComments.includes(answer.key) && answer.commentCount>0" class="mt-5">
                <b-row
                  v-for="(comment, index) in answer.comments"
                  :key="index"
                  class="py-50 border-top"
                >
                  <b-col v-if="comment.createdBy" cols="12" class="pt-1">
                    <b-avatar
                      size="30"
                      :src="getImageSrc(comment.createdBy.avatarURL)"
                      :alt="comment.createdBy.name"
                      class="user-avatar"
                    />
                    <span v-if="comment.createdBy" class="mb-1 ml-50">
                      {{ comment.createdBy.name }}
                      {{ comment.createdBy.surname }}
                    </span>
                    <span
                      v-if="comment.createdBy.headline"
                      class="ml-50 text-muted"
                    >
                      {{ comment.createdBy.headline }}
                    </span>
                    <span
                      v-if="comment.createdAt"
                      class="float-right mt-1 mr-2 text-muted"
                    >
                      {{ timestampToNow(comment.createdAt) }}
                    </span>
                  </b-col>
                  <b-col
                    v-if="editingComment && commentKey === comment.key"
                    cols="12"
                    offset-md="1"
                    offset-lg="0"
                    class="pt-1 px-3 ml-1 mb-2"
                  >
                    <quill-editor
                      v-model="editCommentForm"
                    />
                    <div class="d-flex justify-content-end mt-2">
                      <b-button
                        class="mt-n50 mr-75"
                        variant="primary"
                        @click="handleEditComment"
                      >
                        {{ $t("form.actions.save-changes") }}
                      </b-button>
                      <feather-icon
                        icon="XCircleIcon"
                        size="24"
                        class="text-primary edit-icon"
                        role="button"
                        @click="editingComment = !editingComment"
                      />
                    </div>
                  </b-col>
                  <b-col
                    v-else
                    cols="12"
                    offset-md="1"
                    offset-lg="0"
                    class="pt-1 px-3 ml-1 mb-2"
                  >
                    <p class="mb-2" v-html="translate(comment.content)" />
                    <feather-icon
                      v-if="canDelete(comment) && !editingComment"
                      v-b-modal.modal-unshare-comment
                      icon="Trash2Icon"
                      size="20"
                      class="text-primary edit-icon float-right ml-1"
                      role="button"
                      @click="
                        (commentKey = comment.key),
                        (answerData = answer),
                        (isUnshareCommentModalVisible = true)
                      "
                    />
                    <feather-icon
                      v-if="canEdit(comment) && !editingComment"
                      icon="Edit2Icon"
                      size="20"
                      class="text-primary edit-icon float-right ml-1"
                      role="button"
                      @click="selectComment(comment, answer)"
                    />
                    <span
                      class="hover-button"
                      role="button"
                      @click="
                        toggleLike('comments', 'comment', comment.key, answer)
                      "
                    >
                      <feather-icon
                        size="20"
                        :class="
                          comment.likedByMember
                            ? 'profile-likes-primary'
                            : 'profile-icon'
                        "
                        icon="ThumbsUpIcon"
                        class="mr-25"
                      />

                      <span v-if="!comment.likedByMember">
                        {{ $t("forums.icons.like") }}</span>
                      <span v-else> {{ comment.totalLikes }}</span>
                    </span>
                  </b-col>
                </b-row>
                <div
                  v-if="
                    answer.comments && answer.comments.length > 0 &&
                      answer.comments.length < answer.totalComments
                  "
                  role="button"
                  class="py-2 ml-4 w-100 text-left text-primary"
                  @click="getMoreComments(answer)"
                >
                  {{ $t("action.load-more") }}
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <div
          v-if="forumAnswersAll.length < forumAnswersTotal && !isLoadingNextPage"
          role="button"
          class="pb-2 w-100 text-center text-primary"
          @click="handleLoadOfNewItems"
        >
          {{ $t("action.load-more") }}
        </div>
      </b-card>
      <b-card v-else>
        <!-- Container without data: Placeholder -->
        <b-row class="horizontal-placeholder">
          <b-col cols="12">
            <img :src="Placeholder">
          </b-col>
          <b-col cols="12">
            <p class="text-primary">
              {{
                $t("available.message", {
                  itemName: $tc("forums.answer-title"),
                })
              }}
            </p>
          </b-col>
        </b-row>
      </b-card>

      <!-- ** CREATE ANSWER ** -->
      <b-card id="commentForm" class="p-2">
        <h5 class="mb-2">
          {{ $t("forums.your-reply") }}
        </h5>
        <b-form
          class="form-challenge-idea my-2"
          @submit.prevent="handleCreateItem"
        >
          <quill-editor
            v-model="form.description"
            class="mb-2 w-100 float-left"
          />

          <div class="float-left mb-2">
            <b-button variant="primary" type="submit">
              {{ $t("send.button") }}
            </b-button>
          </div>
        </b-form>
      </b-card>
    </b-col>

    <!-- New post -->
    <b-col cols="12" md="3" style="max-width: 350px">
      <b-card v-if="canAdd">
        <h3 class="mb-1 mt-50">
          {{ $t("forums.new-post.title") }}
        </h3>
        <p>
          {{ $t("forums.new-post.text1") }}<!-- <br>
          {{ $t("forums.new-post.text2") }}-->
        </p>
        <b-button
          variant="primary"
          type="submit"
          class="w-100 my-50"
          @click="openModal"
        >
          {{ $t("forums.new-post.create") }}
        </b-button>
      </b-card>

      <!-- New post list -->
      <div v-if="forumsList.length>1" class="mt-3">
        <h5 class="section-label mb-2">
          {{ $t("forums.more-answers") }}
        </h5>
        <div v-for="forum in forumsList.slice(0, 5)" :key="forum.key">
          <b-link v-if="forum.key!==id" :to="goToForum(forum.key)">
            <h6
              role="button"
              class="mb-25 html-text-ellipsis-2"
            >
              {{ forum.name[currentLocale] || Object.values(forum.name)[0] }}
            </h6>
            <p v-if="forum.ownedByMember" class="text-muted">
              {{ forum.ownedByMember.name }} {{ forum.ownedByMember.surname }}
            </p>
            <hr>
          </b-link>
        </div>
      </div>
    </b-col>

    <!-- Create Forum -->
    <!-- TODO: create create item component -->
    <b-modal
      id="modal-create-forums"
      :title="$t('Forum')"
      cancel-variant="outline-secondary"
      :ok-title="$t('form.actions.save')"
      :cancel-title="$t('form-create-item.cancel')"
      centered
      size="lg"
      @ok="handleCreateForum"
    >
      <b-form>
        <b-form-group>
          <label for="name"> {{ $t("Title") }}:</label>
          <b-form-input
            id="name"
            v-model="forum.name"
            :placeholder="$t('form.placeholder')"
            type="text"
          />
        </b-form-group>
        <b-form-group>
          <label class="mt-2" for="description">{{ $t("Description") }}:</label>
          <quill-editor v-model="forum.description" />
        </b-form-group>

        <b-col color="12" md="6">
          <b-form-group label="Banner" label-for="banner">
            <file-upload
              v-model="imageForum"
              type="image"
              cancelable
              :placeholder="$t('form-create-item.image')"
              :drop-placeholder="$t('form-create-item.drop-placeholder')"
              @cancel="imageSrc = null"
            />
          </b-form-group>
        </b-col>
      </b-form>
    </b-modal>
  </b-row>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';
import moment from 'moment';
import { getImageResource } from '@/@core/utils/image-utils';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import Placeholder from '@/assets/images/placeholders/light/forums.svg';
import Vue from 'vue';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import UnshareItemModal from '@/@core/components/modal/UnshareItemModal.vue';
import FileUpload from '@core/components/files/FileUpload.vue';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { AppIDLUT } from '@copernicsw/community-common';
import { checkPermissions } from '@/@core/utils/roles-utils';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import FilePreview from '@core/components/files/FilePreview.vue';

export default {
  name: 'ForumDetails',
  components: {
    quillEditor,
    UnshareItemModal,
    FileUpload,
    FilePreview,
  },
	  mixins: [ToastNotificationsMixin],

  data() {
    return {
      isLoading: true,
      validForum: [],
      // forum var
      editing: false,
      editForm: { name: '', description: '', bgURL: null },
      lastLoadedPage: 1,
      isUnshareModalVisible: false,
      imageForum: null,
      imageSrc: null,
      image: null,
      forum: { name: '', description: '' },
      // answers var
      editingAnswer: false,
      form: { name: '', description: '' },
      editAnswerForm: {},
      lastLoadedPageAnswers: 1,
      isUnshareAnswerModalVisible: false,
      selectedAnswer: {},
      answerKey: '',
      showComments: [],
      // comments var
      editCommentForm: { description: '' },
      lastLoadedPageComments: 1,
      editingComment: false,
      commentKey: '',
      newComment: '',
      status: 42658,
      isUnshareCommentModalVisible: false,
      isLoadingNextPage: false
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    forumsData() {
      return this.$store.getters.forums;
    },
    item() {
      if (this.forumsData.forum) {
        const item = this.forumsData.forum.unpaginated[0];
      	this.validItem(item.key);
        return item;
      }
      return {};
    },
    forumAnswers() {
      return this.$store.getters.forumAnswers;
    },
    forumAnswersPaginated() {
      return this.$store.getters.forumAnswers.paginated;
    },
    forumAnswersAll() {
      let allForumAnswers = [];
      // console.log('this.forumAnswersPaginated', this.forumAnswersPaginated);
      if(this.forumAnswersPaginated){
        for(const page of Object.values(this.forumAnswersPaginated)) {
          // console.log('page', page);
          for(const item of page){
            allForumAnswers.push(item);
          }
        }
      }
      return allForumAnswers;
    },
    forumAnswersUnpaginated() {
      // console.log('this.forumAnswers', this.forumAnswers)
      return this.forumAnswers.unpaginated;
    },
    forumAnswersMeta() {
      return this.forumAnswers.meta;
    },
    forumAnswersTotal() {
      if (this.forumAnswersMeta) {
        return this.forumAnswersMeta?.total;
      } return 0;
    },
    forumsList() {
      if (this.forumsData.list) {
        return this.forumsData.list?.unpaginated;
      } return [];
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    isStaff() {
      return this.collective.isStaff;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
    canAdd() {
      return (
        this.isStaff
        || checkPermissions(
          'create',
          'forum',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },

    /*     canDelete() {
      return (
        this.loggedUser.key === this.item?.ownedByMember?.key
        || this.isStaff
        || checkPermissions(
          'delete',
          'forum',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    }, */
    Placeholder() {
      return Placeholder;
    },
    appId() {
      return 28;
    },
    apps() {
      return this.$store.getters.apps;
    },
    app() {
      if (this.apps) {
        return this.apps.apps[this.appId] || {};
      }
      return {};
    },
  },
  watch: {
    imageForum(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
    async validForum(value) {
      if (!value.includes(this.id)) {
			 this.isLoading = true;
			 await this.fetchData();
			 if (Object.keys(this.item).length > 0) {
			 // this.validItem(this.item.key);
				 this.updateBreadcrumbs();
				 await this.fetchAnswers();
			 }
			 if (this.forumsList.length === 0) {
				 await this.fetchForumList();
			 }
			 this.isLoading = false;
		 }
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchData();
    if (Object.keys(this.item).length > 0) {
      // this.validItem(this.item.key);
      this.updateBreadcrumbs();
      await this.fetchAnswers();
    }
    if (this.forumsList.length === 0) {
      await this.fetchForumList();
    }
    this.isLoading = false;
  },
  methods: {
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    canEdit(element) {
      return (
        this.isStaff
        || ((this.loggedUser.key === element?.ownedByMember?.key || this.loggedMember.key === element?.createdBy?.key)
         && checkPermissions(
           'update',
           'forum',
           this.loggedMemberRoles,
           this.$store.getters.currentCollective,
         ))
      );
    },
    canDelete(element) {
      return (
        this.isStaff
        || ((this.loggedUser.key === element?.ownedByMember?.key || this.loggedMember.key === element?.createdBy?.key)
         && checkPermissions(
           'delete',
           'forum',
           this.loggedMemberRoles,
           this.$store.getters.currentCollective,
         ))
      );
    },
    canShowCheck(answer) {
      return answer.correctAnswer || (this.canEdit && this.item.status !== 42659 && this.status !== 42659);
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    getBadgeVariant(status) {
      if (!status || status === 42658) {
        return 'warning';
      } if (status === 42659 || status === 42736) {
        return 'success';
      }
      return '';
    },
    getBadgeText(status) {
      if (!status || status === 42658) {
        return this.$t('forums.open');
      } if (status === 42659 || status === 42736) {
        return this.$t('forums.closed');
      }
      return '';
    },
    translate(field) {
      return translateTranslationTable(
        this.currentLocale,
        field,
      );
    },
    timestampToNow(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).fromNow();
    },
    openEdit() {
      this.editForm.name = this.translate(this.item.name);
      this.editForm.description = this.translate(this.item.description);
      this.editForm.bgURL = this.item.bgURL;
      this.editing = !this.editing;
    },
    selectAnswer(answer) {
      this.editAnswerForm = this.translate(answer.description);
      this.editingAnswer = !this.editingAnswer;
      this.selectedAnswer = answer;
      this.answerKey = answer.key;
    },
    selectComment(comment, answer) {
      this.editCommentForm = this.translate(comment.content);
      this.commentKey = comment.key;
      this.editingComment = !this.editingComment;
      this.selectedAnswer = answer;
    },
    openModal() {
      this.$bvModal.show('modal-create-forums');
    },
    goToForum(forumKey) {
      return {
        name: 'forum',
        params: { id: forumKey },
      };
    },
    // GETS
    async fetchData() {
      this.isLoading = true;
      await this.$store.dispatch('getItems', {
        itemType: 'forums',
        storedKey: 'forum',
        page: 1,
        forceAPICall: true,
        requestConfig: {
          count: 5,
          forumKeys: this.id,
        },
      });
      this.isLoading = false;
    },
    async fetchForumList() {
      this.isLoading = true;
      await this.$store.dispatch('getItems', {
        itemType: 'forums',
        storedKey: 'list',
        forceAPICall: true,
        page: this.lastLoadedPage,
        perPage: 10,
      });
      this.isLoading = false;
    },
    async fetchAnswers() {
      const response = await this.$store.dispatch('getItems', {
        itemType: 'forumAnswers',
        morphType: 'forums',
        perPage: 16,
        forceAPICall: true,
        page: this.lastLoadedPageAnswers,
        requestConfig: {
          forumKeys: this.item.key,
        },
      });
      let answers = [];
      this.showComments = [];
      answers = [...response.data];
      answers.forEach((answer) => this.getComments(answer));
    },
    async getComments(answer) {
      const comments = await this.$store.dispatch('getItems', {
        itemType: 'comments',
        morphType: 'answer',
        forceAPICall: true,
        perPage: 16,
        requestConfig: {
          key: answer.key,
          page: this.lastLoadedPageComments,
        },
      });

      let previousComments = [];
      // when it is pagination of the comments, save the previous ones
      if (answer.comments && this.lastLoadedPageComments != 1) { previousComments = this.selectedAnswer.comments; }
      Vue.set(answer, 'comments', [
        ...previousComments,
        ...comments.data,
      ]);
      Vue.set(answer, 'totalComments', comments.meta?.total);
    },
    // POST
    async handleCreateItem() {
      if (!this.form.description) {
        return;
      }
      const requestConfig = {
        name: {},
        description: {},
        forumKey: this.item.key,
      };
      try {
        requestConfig.name[this.currentLocale] = this.form.name;
        requestConfig.description = this.form.description;

        await this.$store.dispatch('createItem', {
          item: {
            itemType: 'forumAnswers',
            orderByDate: 1,
            requestConfig,
          },
        });
        this.form = { name: '', description: '' };
        this.notifySuccess(this.$t('success-message.general-success-create'));
      } catch {
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    async handleCreateForum() {
      if (!this.forum.name) {
        return;
      }
      try {
        const requestConfig = { name: {}, description: {} };
        requestConfig.name[this.currentLocale] = this.forum.name;
        requestConfig.description[this.currentLocale] = this.forum.description;
        const response = await this.$store.dispatch('createItem', {
          item: {
            itemType: 'forums',
            storedKey: 'list',

            requestConfig,
          },
          file: this.imageForum,
        });
        this.forum = { name: '', description: '' };
        this.imageForum = null;
        this.$router.push({ name: 'forum', params: { id: response.data.key } });
        this.updateBreadcrumbs();
        this.notifySuccess(this.$t('success-message.general-success-create'));
      } catch {
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    async postComment(answer) {
      await this.$store.dispatch('postComment', {
        morphType: 'answer',
        key: answer.key,
        comment: this.newComment,
      });
      this.newComment = '';
      answer.commentCount++;
      this.getComments(answer);
      this.notifySuccess(this.$t('success-message.general-success-create'));
      if (!this.showComments.includes(answer.key)) {
        this.showComments.push(answer.key);
      }
    },
    // PUT
    async handleEditItem() {
      try {
        const file = {};
        const itemDelete = {};
        const editedName = {};
        const editedDescription = {};
        if (this.image) {
          file.bgURL = this.image;
        } else if (this.editForm.bgURL !== null) {
          file.bgURL = this.editForm.bgURL;
        } else {
          file.bgURL = null;
          itemDelete.deleteBgURL = true;
        }
        editedName[this.currentLocale] = this.editForm.name;
        editedDescription[this.currentLocale] = this.editForm.description;

        await this.$store.dispatch('editItem', {
          item: {
            itemType: 'forums',
            storedKey: 'forum',
            requestConfig: {
              forumKey: this.id,
              key: this.id,
              name: editedName,
              description: editedDescription,
              ...itemDelete,
            },
          },
          file,
        });
        this.item.name = editedName;
        this.item.description = editedDescription;
        this.item.bgURL = file.bgURL;
        this.editing = !this.editing;
        this.notifySuccess(this.$t('success-message.general-success-edit'));
        this.updateBreadcrumbs();
      } catch {
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    async handleEditAnswer() {
      try {
        const editedAnswer = {};
        const { comments } = this.selectedAnswer;
        editedAnswer[this.currentLocale] = this.editAnswerForm;
        const response = await this.$store.dispatch('editItem', {
          item: {
            itemType: 'forumAnswers',
            morphType: 'forums',
            requestConfig: {
              forumKey: this.item.key,
              answerKey: this.answerKey,
              description: this.editAnswerForm,
            },
          },
        });
        response.data.comments = comments;
        response.data.showReply = this.selectedAnswer.showReply;
        this.answerKey = '';
        this.selectedAnswer = {};
        this.notifySuccess(this.$t('success-message.general-success-edit'));
        this.editingAnswer = !this.editingAnswer;
      } catch {
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    editComment(comment) {
      this.commentKey = comment.key;
      this.editingComment = !this.editingComment;
    },
    async handleEditComment() {
      try {
        let editedComment = {};
        editedComment = this.editCommentForm;
        await this.$store.dispatch('editItem', {
          item: {
            itemType: 'comments',
            morphType: 'forums',
            requestConfig: {
              key: this.commentKey,
              comment: editedComment,
            },
          },
        });
        this.getComments(this.selectedAnswer);
        this.commentKey = '';
        this.answerKey = '';
        this.selectedAnswer = {};
        this.notifySuccess(this.$t('success-message.general-success-edit'));
        this.editingComment = !this.editingComment;
      } catch {
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    // DELETE
    async removeItem(item) {
      try {
        await this.$store.dispatch('unshareForum', {
          item: {
            itemType: 'forums',
            storedKey: 'list',
            forumKey: item.key,
          },
        });
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('unshare.toast-messages.success', { item: 'Forum' }),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        });
        this.isUnshareModalVisible = false;
				 return this.$router.replace({
          name: 'forums',
        });
      } catch (err) {
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    async deleteAnswer(answerKey) {
      await this.$store.dispatch('unshareForum', {
        item: {
          itemType: 'forumAnswers',
          forumKey: this.item.key,
          answerKey,
        },
      });
      --this.item.answerCount;
      this.isUnshareAnswerModalVisible = false;
      this.notifySuccess(this.$t('success-message.general-success-delete'));
      await this.fetchAnswers();
    },
    async deleteComment(commentKey, answerData) {
      await this.$store.dispatch('deleteComment', {
        item: {
          itemType: 'comments',
          morphType: 'answer',
          requestConfig: {
            answerKey: answerData,
            key: commentKey,
          },
        },
      });
      --answerData.commentCount;
      this.getComments(answerData);
      this.isUnshareCommentModalVisible = false;
      this.notifySuccess(this.$t('success-message.general-success-delete'));
    },
    // TOGGLES
    async toggleLike(type, morphType, key, answer, storedKey) {
      await this.$store.dispatch('toggleLike', {
        itemType: type,
        morphType,
        key,
        storedKey,
      });
      if (type === 'forumAnswers') {
        this.fetchAnswers(this.item);
      } else if (type === 'comments') {
        this.getComments(answer);
      }
    },
   	toggleComments(answer) {
      if (this.showComments.includes(answer.key)) {
        const index = this.showComments.findIndex((key) => key === answer.key);
        this.showComments.splice(index, 1);
      } else {
        this.showComments.push(answer.key);
      }
    },
    validItem(itemKey) {
      if (this.validForum.length === 0) {
        this.validForum.push(itemKey);
      } else if (itemKey !== this.id) {
        this.validForum = [];
      }
    },
    async toggleReply(answer) {
      if (!answer.showReply) this.resetInputsReplies();
      if (!answer.showReply) {
        const answerToUpdate = this.forumAnswersAll.find(
          (element) => element.key === answer.key,
        );
        Vue.set(answerToUpdate, 'showReply', true);
      } else if (answer.showReply) {
        answer.showReply = false;
      }
    },
    async selectBestAnswer(answerKey) {
      await this.$store.dispatch('postItem', {
        type: 'bestAnswers',
        requestConfig: {
          forumKey: this.item.key,
          answerKey,
        },
      });
      this.closeForum();
    },
    closeForum() {
      this.$store
        .dispatch('postItem', {
          type: 'forumStatus',
          requestConfig: {
            status: 42659,
            forumKey: this.item.key,
          },
        })
        .then((response) => {
          this.status = 42659;
          // this.answers.data = [];
          this.fetchAnswers();
          this.$store.commit('EDIT_ITEM', {
            itemType: 'forums',
            storedKey: 'forum',
            data: response.data,
          });
          this.getBadgeVariant(42659);
          this.getBadgeText(42659);
        });
    },
    resetInputsReplies() {
      this.forumAnswersAll.forEach((answer) => {
        if (answer.showReply) Vue.set(answer, 'showReply', false);
      });
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text:
          translateTranslationTable(
            this.$store.state.locale.currentLocale,
            this.app.customizationName
            ,
          ) || this.$t('Forum'),
          to: { name: 'forums' },
        },

      ];
      if (this.item) {
        breadcrumbs.push({
          text: this.translate(this.item.name) || 'forums.breadcrumb-text.forum-details',
          active: true,
        });
      }
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    async handleLoadOfNewItems() {
      // console.log('this.forumAnswersAll', this.forumAnswersAll);
      // console.log('this.forumAnswersMeta', this.forumAnswersMeta)
      const total = this.forumAnswersMeta?.total;
      // console.log('this.isLoading', this.isLoading);
      // console.log('this.forumAnswersAll.length', this.forumAnswersAll.length);
      // console.log('total', total);
      if (!this.isLoading && this.forumAnswersAll.length < total) {
        this.lastLoadedPageAnswers ++;
        this.isLoadingNextPage = true;
        await this.fetchAnswers({ 
          forceApiCall: true
         });
        this.isLoadingNextPage = false;
      }
    },
    async getMoreAnswers(){
      this.lastLoadedPageAnswers ++;
      await this.fetchAnswers();
    },
    async getMoreComments(answer){
      this.lastLoadedPageComments ++;
      await this.getComments(answer);
    }
  },
};
</script>
<style>
.content-container::v-deep img {
  max-width: 100% !important;
  height: auto !important;
}
.icons {
  position: absolute;
  margin-top: -50px;
}
</style>
